import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticStyle: { "min-width": "100%", "padding-left": "0px" } },
    [
      _c(
        "div",
        [
          _c("font-awesome-icon", {
            staticClass: "arrow-back",
            attrs: { icon: "fa-solid fa-arrow-left" },
            on: {
              click: function ($event) {
                return _vm.handleInvestorRedirectAfterCompleteProfile()
              },
            },
          }),
          _c("div", { staticClass: "verify-title-container mobile-padding" }, [
            _vm.verifyStep === _vm.verifyProfileEnums.SUCCESSFULLY_VERIFIED
              ? _c(
                  "div",
                  { staticClass: "icon-check-profile-container" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon-check-profile",
                      attrs: { icon: "fa-solid fa-user-check" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("h1", { staticClass: "verify-title" }, [
              _vm.verifyStep === _vm.verifyProfileEnums.VERIFY_ACCOUNT
                ? _c("span", [_vm._v(_vm._s(_vm.$t("verify_your_account")))])
                : _vm.verifyStep ===
                  _vm.verifyProfileEnums.SUCCESSFULLY_VERIFIED
                ? _c("span", [_vm._v(_vm._s(_vm.$t("account_verify_success")))])
                : _vm._e(),
            ]),
            _vm.verifyStep === _vm.verifyProfileEnums.VERIFY_ACCOUNT
              ? _c("p", { staticClass: "verify-subtitle" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("verify_account_warning_text")) + " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "verify-required-steps-container mobile-padding" },
            [
              _c("div", { staticClass: "verify-required-steps" }, [
                _c("span", { staticClass: "verify-required-steps-title" }, [
                  _vm._v(_vm._s(_vm.$t("required_steps"))),
                ]),
                _c("ul", { staticClass: "steps-list" }, [
                  _c("li", { staticClass: "item" }, [
                    !_vm.checkCompliance
                      ? _c("span", { staticClass: "check" })
                      : _c(
                          "div",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon-successfully-verify-check",
                              attrs: { icon: "fa-solid fa-circle-check" },
                            }),
                          ],
                          1
                        ),
                    _c("div", { staticClass: "registration-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("investor_form_compliance"))),
                      ]),
                      _c("span", { staticClass: "sub-title" }, [
                        _vm._v(_vm._s(_vm.$t("investor_form_compliance_text"))),
                      ]),
                    ]),
                  ]),
                  _c("li", { staticClass: "item" }, [
                    !_vm.checkComplyCube
                      ? _c("span", { staticClass: "check" })
                      : _c(
                          "div",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon-successfully-verify-check",
                              attrs: { icon: "fa-solid fa-circle-check" },
                            }),
                          ],
                          1
                        ),
                    _c("div", { staticClass: "registration-item" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("investor_form_complycube"))),
                      ]),
                      _c("span", { staticClass: "sub-title" }, [
                        _vm._v(_vm._s(_vm.$t("investor_form_complycube_text"))),
                      ]),
                    ]),
                  ]),
                ]),
                _vm.verifyStep === _vm.verifyProfileEnums.SUCCESSFULLY_VERIFIED
                  ? _c(
                      "span",
                      { staticClass: "complete-profile-success-text" },
                      [_vm._v(_vm._s(_vm.$t("all_right")))]
                    )
                  : _vm._e(),
                _vm.verifyStep === _vm.verifyProfileEnums.VERIFY_ACCOUNT
                  ? _c(
                      "div",
                      { staticClass: "complete-profile-button-container" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "complete-profile-button",
                            attrs: {
                              color: "#1A56DB",
                              "data-test": "Alpha:Verify:BtnCompleteRegister",
                              loading: _vm.complyCubeLoading,
                            },
                            on: { click: _vm.handleCompleteRegistration },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("continue")) + " "),
                            ]),
                            _c("font-awesome-icon", {
                              staticClass: "complete-profile-button-icon",
                              attrs: { icon: "fa-solid fa-arrow-right " },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "verify-footer" }, [
        _c("p", { staticClass: "verify-footer-text" }, [
          _vm._v(" " + _vm._s(_vm.$t("read_more_about_1")) + " "),
          _c(
            "span",
            {
              staticClass: "link",
              on: {
                click: function ($event) {
                  return _vm.openPrivacyPolicy()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("privacy_policy")) + " ")]
          ),
          _vm._v(" " + _vm._s(_vm.$t("read_more_about_2")) + " "),
        ]),
      ]),
      _c("div", { attrs: { id: "complycube-mount" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }