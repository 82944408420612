import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "loading-spinner" },
          [
            _c(VProgressCircular, {
              attrs: { indeterminate: "", size: "70", color: "primary" },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("VerifyProfile", {
              attrs: {
                verifyStep: _vm.verifyStep,
                checkCompliance: _vm.investorProfile.ProfileDone,
                checkComplyCube: _vm.checkComplyCube,
              },
              on: {
                handleCompleteComplyCubeRegister:
                  _vm.handleCompleteComplyCubeRegister,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }